import "./App.css";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  const [heartbeats, setHeartbeats] = useState([]);
  const [pulse, setPulse] = useState(0);
  const [resetOnNextTab, setResetOnNextTab] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const timeoutRef = useRef(0);
  const minHeartbeatsRecorded = 15;
  const maxHeartbeatsLength = 16;
  const timeoutDelay = 2500;
  const pulseMeasuredVibrationPattern = [200];
  const handleTab = (e) => {
    if (resetOnNextTab) {
      setHeartbeats([]);
      setResetOnNextTab(false);
    }
    setHeartbeats((prev) => {
      let heartbeatsNew = prev.concat(Date.now());
      // if (heartbeatsNew.length > maxHeartbeatsLength) {
      heartbeatsNew.splice(0, heartbeatsNew.length - maxHeartbeatsLength);
      // }
      // console.log(heartbeatsNew);
      let timeDiffFactor =
        (60 / (heartbeatsNew[heartbeatsNew.length - 1] - heartbeatsNew[0])) *
        1000;
      setPulse(Math.round(heartbeatsNew.length * timeDiffFactor));
      heartbeatsNew.length === minHeartbeatsRecorded &&
        navigator.vibrate(pulseMeasuredVibrationPattern);
      return heartbeatsNew;
    });
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setResetOnNextTab(true);
    }, timeoutDelay);
  };

  const handleHelpClick = (e) => {
    setShowHelp((prevState) => !prevState);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      <p>
        {t("Tab the circle on every heartbeat.")}
        <button
          onClick={handleHelpClick}
          className={showHelp ? "help-button active" : "help-button"}
        >
          ?
        </button>
      </p>
      {showHelp ? (
        <ul className="help-container">
          <li>{t("instructions.part1")}</li>
          <li>{t("instructions.part2")}</li>
          <li>{t("instructions.part3", { minHeartbeatsRecorded })}</li>
          {/* <li>
            If your device supports vibration I'll use that in order to get you
            to opening your eyes again. (Your device can't be in Do Not Disturb
            mode for this feature to work.)
          </li> */}
        </ul>
      ) : (
        ""
      )}
      <button
        onClick={handleTab}
        className="pulse-button"
        dangerouslySetInnerHTML={{
          __html: !resetOnNextTab
            ? heartbeats.length === 0
              ? t("Start tabbing")
              : heartbeats.length >= minHeartbeatsRecorded
              ? pulse
              : t("Keep tabbing..")
            : heartbeats.length >= minHeartbeatsRecorded
            ? `<div class="small-text">${t("Last heart rate:")} ${pulse}</div>
              <div class="xsmall-text">${t("Start tabbing again")}</div>`
            : `<div class="xsmall-text">${t("Start tabbing again")}</div>`,
        }}
      ></button>
      <a
        className="App-link"
        href="https://nepomuc.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("Created by nepomuc")}
      </a>
      <div className="language-switch">
        <button
          className={i18n.language === "en" ? "active" : ""}
          onClick={() => changeLanguage("en")}
        >
          English
        </button>
        <button
          className={i18n.language === "de" ? "active" : ""}
          onClick={() => changeLanguage("de")}
        >
          Deutsch
        </button>
      </div>
      <div className="disclaimer">{t("Disclaimer text")}</div>
    </div>
  );
}

export default App;
